$primary: #ffb841; /* MAIN COLOR */
$secondary: #77B2D4; /* SECONDARY COLOR */
$tertiary: #86a228;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaina|Roboto');

.flash {
	display:none;
}
h1,h2,h3 {
	font-family: 'Baloo Bhaina', cursive;
}
p {
	font-family: 'Roboto', sans-serif;
}
body {
	background: url(../img/bg.jpg) no-repeat fixed;
	background-size: cover;
	@media (max-width: 1299px) {
		background: $secondary;
	}
}
.solidBg  {
	background: white;
	.txt {
		h1 {
			font-size: 3em;
			color: $primary;
			@media (max-width: 991px) {
				font-size: 2.2em;
			}
		}
		p {
			font-size: 1.8em;
			@media (max-width: 767px) {
				font-size: 1.2em;
			}
		}
	}
}
.noPad {
	padding: 0;
}
.noPadMarg {
	padding: 0;
	margin: 0;
}
.padTopBtm {
	padding: 75px 15px;
	@media (max-width: 991px) {
		padding: 30px 15px;
	}
}

/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 90px;
			display: flex;
			align-items: center;


			@media (max-width: 767px) {
			    height: 28px;
			    display: inline-block;
			    padding: 5px;
			}

		    &:hover {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    }

			&:focus {
			  background: transparent;
			  color: #777;
			  outline: 0;
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}


.navbar-toggle {
	margin-top: 20px;
}


/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;
		white-space: nowrap;

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    } 
}


.banner {
	background: url(../img/banner.jpg) no-repeat center;
	background-size: cover;
	padding: 20em 0em;
	max-width: 1170px;
	width: 100%;
	display: block;
	margin: 0 auto;
	@media (max-width: 991px) {
		padding: 14em 0;
	}
	@media (max-width: 767px) {
		padding: 11em 0;
	}
	position: relative;
	.btmTxt {
		position: absolute;
		bottom: 0;
		background: rgba($secondary, 0.7);
		width: 100%;
		h1 {
			text-align: center;
			color: white;
			font-size: 3.4em;
			@media (max-width: 991px) {
				font-size: 2.2em;
			}
		}
	}
}

a.btn {
	@include btn1;
	margin: 20px 0;
	@media (max-width: 991px) {
		font-size: 1em;
	}
	@media (max-width: 767px) {
		padding: 5px;
	}
	&:hover, &:focus, &:active {
		color: white;
		text-decoration: none;
		outline: 0;
		background: $primary;
		text-decoration: none;
	}
}

.imgBox {
	.midImg1 {
		background: linear-gradient(
           rgba($primary, 0.7),
           rgba($primary, 0.7)
			),url(../img/midImg1.jpg) no-repeat;
		background-size: cover;
		background-position: 60% 50%;
		height: 350px;
		color: white;
		h1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 3em;
			@media (max-width: 991px) {
				font-size: 2.2em;
			}
		}
	}
	.midImg2 {
		background: linear-gradient(
           rgba($secondary, 0.7),
           rgba($secondary, 0.7)
			),url(../img/midImg2.jpg) no-repeat;
		background-size: cover;
		color: white;
		height: 350px;
		h1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 3em;
			@media (max-width: 991px) {
				font-size: 2.2em;
			}
		}
	}
}

	.midImgBtm {
		background: linear-gradient(
           rgba($tertiary, 0.7),
           rgba($tertiary, 0.7)
			), url(../img/midImgBtm.jpg) no-repeat;
		background-size: cover;
		height: 300px;
		position: relative;
		@media (max-width: 991px) {
			background-position: 70% 50%;
		}
		h1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 3em;
			color: white;
			@media (max-width: 991px) {
				font-size: 2.2em;
			}
		}
	}

.fixedNav {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
	max-width: 1170px;
	left: 50%;
	transform: translateX(-50%);
}

.privacyTerms {
	background: white;
}

.showSmall {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}